import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import Link from '../Link';

const Banner = (props) => {
  return (
    <Link to={props.path} { ...props }>
      <Img fluid={props.image} />
    </Link>
  );
};

export default Banner;
